
import { defineComponent, onMounted, reactive, watch} from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import mAxiosApi from "@/api";
import Draggable from "vue3-draggable";
// import TaskCard from "@/views/still/kanban/TaskCard.vue";

export default defineComponent({
  name: "private-chat",
  components: {
    Draggable,
  },

  setup() {

    const router = useRouter();

    const state = reactive({
      messages : [] as any,
      idCurrent:0,
      columns: [
        {
          
          title: "A traiter",
          color: "#ffeebfad",
          tasks: [
            {
              id: 1,
              title: "Vérifier la cohérence du ticket",
              sub: "Stock disponible ?",
              type: "Validation",
              initial: "TC"
            },
            {
              id: 2,
              title: "Etablir et envoyer une offre commerciale",
              type: "Validation",
              sub: "Si facturable",
              initial: "TC"
            },
            {
              id: 3,
              title: "Valider le ticket",
              type: "Validation",
              sub: "",
              initial: "TC"
            },
            {
              id: 4,
              title: "Transmettre sortie de stock ISOPartner",
              type: "Expédition",
              sub: "Avec Bon de livraison",
              initial: "CM"
            },
            {
              id: 5,
              title: "Récupérer les informations de livraison",
              type: "Expédition",
              sub: "Auprès du partenaire",
              initial: ""
            },
            {
              id: 6,
              title: "Valider l'expédition",
              type: "Expédition",
              sub: "",
              initial: ""
            },
            {
              id: 7,
              title: "Facturer l'offre lié au ticket",
              type: "Facturation",
              sub: "",
              initial: "ST"
            },
          ]
        },
        {
          title: "En cours de traitement",
          color: "#bfe7ffad",
          tasks: []
        },
        {
          title: "Fini",
          color: "#c3ffbfad",
          tasks: []
        },
      ]
    });


    watch(state.columns, (oldVal, newVal) => {
      console.log("Old", oldVal);
      console.log("New", newVal);
    });

    const selectTicket = (id) => {
      state.idCurrent = id;
      window.document.title = id;
    }

    onMounted(async() => {
        window.document.title = "timeKanban";
        if (!store.getters.isUserAuthenticated){
            router.push({ name: "sign-in" });
        }
    });

    return {
      state,
      selectTicket,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

