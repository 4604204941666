<template>
  <el-row class="m-5">
    <el-col :span="Math.trunc(24/state.columns.length)"  v-for="column in state.columns" :key="column.title" >
      <div :style="`background: #ffffffc2;`" class="rounded mx-2 pb-10 pt-1">

        <div class="fs-6 m-3 p-4 text-dark" :style="`background: ${column.color}; border-radius: 5px;`" ><b>{{column.title}}</b></div>

        <draggable v-model="column.tasks" style="min-height: 80vh;" transition="50"  class="m-3">
            <template v-slot:item="{item}">


              <a class="card border border-2 border-gray-300 border-hover mb-3 task-item" 
                @click="selectTicket(item.id)" :style="`background-color: ${state.idCurrent == item.id ? 'rgb(225,239,249) !important' : 'white'}`"
              >
                <div class="card-header border-0 px-5" style="min-height: 50px !important;">
                  <div class="card-title m-0">
                    <div class="symbol symbol-30px symbol-circle"  data-bs-toggle="tooltip" title="Julien Letienne">
                      <span v-if="item.initial" class="symbol-label fw-bolder fs-7 bg-light-dark text-inverse-secondary">{{item.initial }}</span>
                      <span v-else class="symbol-label fw-bolder fs-6 bg-light-danger text-danger">?</span>
                    </div>
                  </div>
                  <div class="card-toolbar">
                    <span class="badge badge-small badge-light-primary fw-bolder me-auto px-4 py-2">{{ item.type }}</span
                    >
                  </div>
                </div>
                <div class="card-body p-0 px-5 ">
                  <div class="fs-6 fw-bolder text-dark">
                    {{ item.title }}
                  </div>
                  <p class="text-gray-400 fw-bold fs-7 mt-1 mb-7">
                    {{ item.sub }}
                  </p>


                </div>
              </a>

            </template>
        </draggable>

      </div>
    </el-col>
  </el-row>


</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, watch} from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import mAxiosApi from "@/api";
import Draggable from "vue3-draggable";
// import TaskCard from "@/views/still/kanban/TaskCard.vue";

export default defineComponent({
  name: "private-chat",
  components: {
    Draggable,
  },

  setup() {

    const router = useRouter();

    const state = reactive({
      messages : [] as any,
      idCurrent:0,
      columns: [
        {
          
          title: "A traiter",
          color: "#ffeebfad",
          tasks: [
            {
              id: 1,
              title: "Vérifier la cohérence du ticket",
              sub: "Stock disponible ?",
              type: "Validation",
              initial: "TC"
            },
            {
              id: 2,
              title: "Etablir et envoyer une offre commerciale",
              type: "Validation",
              sub: "Si facturable",
              initial: "TC"
            },
            {
              id: 3,
              title: "Valider le ticket",
              type: "Validation",
              sub: "",
              initial: "TC"
            },
            {
              id: 4,
              title: "Transmettre sortie de stock ISOPartner",
              type: "Expédition",
              sub: "Avec Bon de livraison",
              initial: "CM"
            },
            {
              id: 5,
              title: "Récupérer les informations de livraison",
              type: "Expédition",
              sub: "Auprès du partenaire",
              initial: ""
            },
            {
              id: 6,
              title: "Valider l'expédition",
              type: "Expédition",
              sub: "",
              initial: ""
            },
            {
              id: 7,
              title: "Facturer l'offre lié au ticket",
              type: "Facturation",
              sub: "",
              initial: "ST"
            },
          ]
        },
        {
          title: "En cours de traitement",
          color: "#bfe7ffad",
          tasks: []
        },
        {
          title: "Fini",
          color: "#c3ffbfad",
          tasks: []
        },
      ]
    });


    watch(state.columns, (oldVal, newVal) => {
      console.log("Old", oldVal);
      console.log("New", newVal);
    });

    const selectTicket = (id) => {
      state.idCurrent = id;
      window.document.title = id;
    }

    onMounted(async() => {
        window.document.title = "timeKanban";
        if (!store.getters.isUserAuthenticated){
            router.push({ name: "sign-in" });
        }
    });

    return {
      state,
      selectTicket,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

</script>

<style>
  body {
    background: #f4f7fd !important;
  }
  .drop-zone {
    box-shadow: 0px 3px 5px #aaa;
    margin: 10px;
    padding-top:3px;
  }
  .task-item {
    box-shadow: rgb(187 187 187) 1px 1px 6px -3px !important;
    cursor: pointer;
    transition: all 0.10s;
  }
  .task-item:hover{
    box-shadow: rgb(187 187 187) 1px 1px 14px -3px !important;
    }
</style>
